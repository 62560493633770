<template>
	<main>
		<div class="column__wrapper">
			<div class="column column__left">
				<AccountMenu />
			</div>
			<div class="column column__right">
				<ContactUs />
			</div>
		</div>
	</main>
</template>

<script>
import AccountMenu from "@/components/AccountMenu";
import ContactUs from "@/views/ContactUs";
export default {
	name: "Help",
	components: {AccountMenu, ContactUs},
}
</script>

<style lang="scss" scoped>

:deep .contact {
	width: 100%;
	margin-top: 0;

	h3 {
		text-align: left;
	}
}

:deep form {
	margin: 0 0;
}

</style>